import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ProductDetails from 'pages/extra-pages/ProductDetails';
// import Transaction from 'pages/extra-pages/Transaction';
import ShippingProduct from 'pages/extra-pages/ShippingProduct';
// import ProviderProfile from 'pages/extra-pages/ProviderDetails';
// import SalesTax from 'pages/extra-pages/SalesTax';
import ClaimUser from "../pages/extra-pages/ClaimUser";
// import ClaimUserView from "../pages/extra-pages/ClaimUserView";
import EditClaimUser from "../pages/extra-pages/EditClaimUser/EditClaimUser";
// import ReferralCodeUsers from "../pages/extra-pages/ReferralCodes/ReferralCodeUsers";
// import DiscountCode from "../pages/extra-pages/Discount";
// import GenerateDiscountCode from "../pages/extra-pages/Discount/GenerateDiscountCode/GenerateDiscountCode";
// import BannerImage from "../pages/extra-pages/BannerImage/BannerImage";
// import BannerImageView from "../pages/extra-pages/BannerImage/BannerImageView";
// import TransactionView from "../pages/extra-pages/TransactionView";
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page

// render - utilities
const Users = Loadable(lazy(() => import('pages/extra-pages/Users')));
const Categories = Loadable(lazy(() => import('pages/extra-pages/Categories')));
const Providers = Loadable(lazy(() => import('pages/extra-pages/Providers')));
const Event = Loadable(lazy(() => import('pages/extra-pages/Event')));
const Services = Loadable(lazy(() => import('pages/extra-pages/Services')));
const Profile = Loadable(lazy(() => import('pages/extra-pages/Profile')));
const Transaction = Loadable(lazy(() => import('pages/extra-pages/Transaction')));
const BannerImage = Loadable(lazy(() => import('pages/extra-pages/BannerImage/BannerImage')));
const ProviderProfile = Loadable(lazy(() => import('pages/extra-pages/ProviderDetails')));
const TransactionView = Loadable(lazy(() => import('pages/extra-pages/TransactionView')));
const BannerImageView = Loadable(lazy(() => import('pages/extra-pages/BannerImage/BannerImageView')));
const ClaimUserView = Loadable(lazy(() => import('pages/extra-pages/ClaimUserView')));
const SalesTax = Loadable(lazy(() => import('pages/extra-pages/SalesTax')));
const Support = Loadable(lazy(() => import('pages/extra-pages/Support/Support')));
const ReferralCodes = Loadable(lazy(() => import('pages/extra-pages/ReferralCodes')));
const GenerateCode = Loadable(lazy(() => import('pages/extra-pages/ReferralCodes/GenerateCode/GenerateCode')));
const DiscountCode = Loadable(lazy(() => import('pages/extra-pages/Discount')));
const ReferralCodeUsers = Loadable(lazy(() => import('pages/extra-pages/ReferralCodes/ReferralCodeUsers')));
const GenerateDiscountCode = Loadable(lazy(() => import('pages/extra-pages/Discount/GenerateDiscountCode/GenerateDiscountCode')));
const DiscountCodeUser = Loadable(lazy(() => import('pages/extra-pages/Discount/DiscountCodeUser')));
const PrePopEvent = Loadable(lazy(() => import('pages/extra-pages/PrePopulateEvent')));
const CreateEvent = Loadable(lazy(() => import('pages/extra-pages/PrePopulateEvent/CreateEvent')));
const ViewEvent = Loadable(lazy(() => import('pages/extra-pages/PrePopulateEvent/EventDetails')));
const Reports = Loadable(lazy(() => import('pages/extra-pages/Report/Reports')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            element: <DashboardDefault />
        },
        {
            path: 'users',
            element: <Users />
        },
        {
            path: 'users/:number',
            element: <Users />
        },
        {
            path: 'categories',
            element: <Categories />
        },
        {
            path: 'provider',
            element: <Providers />
        },
        {
            path: 'provider/:number',
            element: <Providers />
        },
        {
            path: 'events',
            element: <Event />
        },
        {
            path: 'events/:number',
            element: <Event />
        },
        {
            path: 'services/:number',
            element: <Services />
        },
        {
            path: 'services',
            element: <Services />
        },
        {
            path: 'transaction',
            element: <Transaction />
        },
        {
            path: 'shippingproduct',
            element: <ShippingProduct />
        },
        {
            path: 'profile/:profile_id',
            element: <Profile />
        },
        {
            path: 'productdetails/:product_id',
            element: <ProductDetails />
        },
        {
            path: 'providerdetails/:profile_id',
            element: <ProviderProfile />
        },
        {
            path: 'sales-tax',
            element: <SalesTax />
        },
        {
            path: 'sales-tax/:number',
            element: <SalesTax />
        },
        {
            path: 'pre-polutation',
            element: <ClaimUser />
        },
        {
            path: 'pre-polutation/:number',
            element: <ClaimUser />
        },
        {
            path: 'pre-polutation-user/:id',
            element: <ClaimUserView />
        },
        {
            path: 'edit-pre-polutation/:id',
            element: <EditClaimUser />
        },
        {
            path: 'banner-image',
            element: <BannerImage />
        },
        {
            path: 'banner-image/:id',
            element: <BannerImageView />
        },
        {
            path: 'transactions',
            element: <Transaction />
        },
        {
            path: 'transactions/:number',
            element: <Transaction />
        },
        {
            path: 'transactions-details/:id',
            element: <TransactionView />
        },
        {
            path: 'support',
            element: <Support />
        },
        {
            path: 'support/:number',
            element: <Support />
        },
        {
            path: 'referral-codes',
            element: <ReferralCodes />
        },
        
        {
            path: 'referral-codes/:number',
            element: <ReferralCodes />
        },
        {
            path: 'generate-code',
            element: <GenerateCode />
        },
        {
            path: 'edit-code/:id',
            element: <GenerateCode />
        },
        {
            path: 'referral-codes-details/:id',
            element: <ReferralCodeUsers />
        },
        {
            path: 'referral-codes-details/:id/:number',
            element: <ReferralCodeUsers />
        },
        {
            path: 'discount-codes',
            element: <DiscountCode />
        },
        {
            path: 'discount-codes/:number',
            element: <DiscountCode />
        },
        {
            path: 'discount-codes-user/:id',
            element: <DiscountCodeUser />
        },
        {
            path: 'discount-codes-user/:id/:number',
            element: <DiscountCodeUser />
        },
        {
            path: 'generate-discount-codes',
            element: <GenerateDiscountCode />
        },
        {
            path: 'edit-discount-code/:id',
            element: <GenerateDiscountCode />
        },
       
        {
            path: 'pre-pop-events/:number',
            element: <PrePopEvent />
        },
        {
            path: 'pre-pop-events',
            element: <PrePopEvent />
        },
        {
            path: 'create-event',
            element: <CreateEvent />
        },
        {
            path: 'create-event/:id',
            element: <CreateEvent />
        },
        {
            path: 'view-events/:id/:providerId',
            element: <ViewEvent />
        },
        {
            path: 'reports',
            element: <Reports />
        },
        {
            path: 'reports/:number',
            element: <Reports />
        },
    ]
};

export default MainRoutes;
