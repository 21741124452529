import React, {useState} from 'react';
import {Box, Grid} from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import plusIcon from "../../../assets/images/whiteplus.png"
import shareIcon from "../../../assets/images/shareIcon.svg"
import {Tooltip} from "antd";

const IntroNlink = ({inputData, setInputData, deleteModal, setDeleteModal, setlinkRemoveNo, linkRemoveNo}) => {

    const [linkError, setLinkError] = useState("");

    function isValidURL(str) {
        const pattern = new RegExp(
            '^([a-zA-Z]+:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
        );
        if (pattern.test(str)) {
            return true
        } else {
            return false
        }
    }

    const redirectLink = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    return (
        <>
            {/*<div className="main-inner">*/}
            {/*<div className="container">*/}
            {/*.........page section.........*/}

            <Box>

                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Box className="inner-gred page-Container-height">
                                <p>Introduction </p>
                                <Box className="border-gred">
                                    <TextArea
                                        value={inputData?.provider_introduction}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 350) {
                                                setInputData({
                                                    ...inputData, provider_introduction: e.target.value,
                                                });
                                            }

                                        }}
                                        rows={8}
                                        cols={6}
                                        className="inner-gred"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className="inner-gred page-Container-height">
                                <Box className="d-flex justify-content-between align-items-center">
                                    <p>Website links</p>
                                    <span
                                        className="plus-icon float-end"
                                        onClick={() => {
                                            if (inputData?.website_link) {

                                                if (isValidURL(inputData?.website_link[inputData?.website_link?.length - 1])) {

                                                    setLinkError("")
                                                    setInputData({
                                                        ...inputData,
                                                        website_link: [...inputData?.website_link, ""],
                                                    });
                                                } else if (inputData?.website_link?.length == 0) {

                                                    setInputData({
                                                        ...inputData,
                                                        website_link: [...inputData?.website_link, ""],
                                                    });
                                                } else {

                                                    setLinkError("Please Enter Valid Link")
                                                }
                                            } else {
                                                setInputData({
                                                    ...inputData,
                                                    website_link: [...inputData?.website_link || "", ""],
                                                });
                                            }

                                        }}
                                    >
                                        <svg
                                            width="30"
                                            height="30"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="add">
                                                <g id="vuesax/outline/add">
                                                    <g id="add_2">
                                                        <path
                                                            id="Vector"
                                                            d="M22.5 15.9375H7.5C6.9875 15.9375 6.5625 15.5125 6.5625 15C6.5625 14.4875 6.9875 14.0625 7.5 14.0625H22.5C23.0125 14.0625 23.4375 14.4875 23.4375 15C23.4375 15.5125 23.0125 15.9375 22.5 15.9375Z"
                                                            fill="#292D32"
                                                        />
                                                        <path
                                                            id="Vector_2"
                                                            d="M15 23.4375C14.4875 23.4375 14.0625 23.0125 14.0625 22.5V7.5C14.0625 6.9875 14.4875 6.5625 15 6.5625C15.5125 6.5625 15.9375 6.9875 15.9375 7.5V22.5C15.9375 23.0125 15.5125 23.4375 15 23.4375Z"
                                                            fill="#292D32"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </Box>
                                <Box>
                                    <Box style={{height: "225px", overflow: "auto"}}>
                                        {inputData?.website_link?.map((data, i) => {
                                            return (<>
                                                <div className="group-input mt-2 p-15 clearfix">
                                                    <div className="bg-white">
                                                        <input
                                                            type="url"
                                                            name=""
                                                            style={{
                                                                width: '88%',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                            value={inputData?.website_link[i]}
                                                            onChange={(e) => {
                                                                const newValue = inputData?.website_link?.map((value, j) => {
                                                                    if (i == j) {
                                                                        return e.target.value;
                                                                    } else {
                                                                        return value;
                                                                    }
                                                                });
                                                                setInputData({
                                                                    ...inputData, website_link: newValue,
                                                                });
                                                            }}
                                                            placeholder="Your Link here"
                                                        />
                                                    </div>


                                                    {/*    ================= link redirect =============== */}
                                                    <Tooltip placement="top" title={'Open Link'}>
                                                        <span
                                                            className="remove shareLink new-remove"
                                                            style={{
                                                                top: "16px",
                                                                right: "44px",
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={() => {
                                                                redirectLink(inputData?.website_link[i])
                                                            }}

                                                        >
                                                          <img src={shareIcon} alt="" style={{width: '80%'}}/>
                                                        </span>
                                                    </Tooltip>


                                                    {/*    ================= * =============== */}


                                                    {inputData?.website_link?.length > 1 && (<>
                                                        <Tooltip placement="top" title={'Delete Link'}>
                                                            <span
                                                                className="remove new-remove"
                                                                style={{top: "16px", right: "16px"}}
                                                                onClick={() => {
                                                                    setDeleteModal((pre) => ({
                                                                        ...pre, link: true,
                                                                    }));
                                                                    setlinkRemoveNo(i);
                                                                    setLinkError("")
                                                                }}
                                                                // onClick={() => {
                                                                //     setWebLinks({
                                                                //         ...webLinks,
                                                                //         webLinks: webLinks?.filter((datanew, j) => {
                                                                //             return i !== j;
                                                                //         }),
                                                                //     });
                                                                // }}
                                                            >
                                                            <svg
                                                                style={{position: "initial"}}
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g id="Icons">
                                                                    <path
                                                                        id="Vector"
                                                                        d="M12.3865 12.8635L7.61351 8.09054M7.61351 12.8635L12.3865 8.09054"
                                                                        stroke="#FF0000"
                                                                        strokeWidth="1.39212"
                                                                        stroke-linecap="round"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        </Tooltip>
                                                    </>)}
                                                </div>
                                            </>);
                                        })}
                                        <p className="t-14-bold" style={{color: "red"}}>{linkError}</p>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {/*.........Services section.......... */}

            {/*{*/}
            {/*    <Box className="mt-5">*/}
            {/*        <h3 style={{fontWeight: "600", marginBottom: "25px"}}>*/}
            {/*            My Services*/}
            {/*        </h3>*/}
            {/*        <Box>*/}
            {/*            <Grid container spacing={2}>*/}
            {/*                {inputData?.my_page_service_detail?.map((service, i) => (*/}
            {/*                    <Grid item xs={12} sm={6} lg={4}>*/}
            {/*                        <Box className="border-gred">*/}
            {/*                            <Box className="inner-gred">*/}
            {/*                                <Box className="d-flex align-items-center justify-content-between">*/}
            {/*                                    <h4 className="input-label" style={{*/}
            {/*                                        wordWrap: "normal",*/}
            {/*                                        width: "85%",*/}
            {/*                                        textWrap: "wrap",*/}
            {/*                                        wordBreak: "break-word",*/}
            {/*                                    }}>*/}
            {/*                                        {service?.service_name}*/}
            {/*                                    </h4>*/}
            {/*                                    <Box>*/}
            {/*                                        <IconButton*/}
            {/*                                            onClick={() => {*/}
            {/*                                                setserviceForm({*/}
            {/*                                                    service: service?.service_name,*/}
            {/*                                                    details: service?.service_type,*/}
            {/*                                                    details_link: service?.link,*/}
            {/*                                                    location: service?.location_type,*/}
            {/*                                                    adress: service?.address,*/}
            {/*                                                    time: "",*/}
            {/*                                                    session_price: service?.session_price,*/}
            {/*                                                    member: service?.participants,*/}
            {/*                                                    meximum_member: service?.maximum_participants,*/}
            {/*                                                    addition_price: service?.additional_price,*/}
            {/*                                                    discription: service?.description,*/}
            {/*                                                    locationCordinates: service?.location,*/}
            {/*                                                    session_hours: service?.session_hour,*/}
            {/*                                                    session_minutes: service?.session_minute,*/}
            {/*                                                });*/}
            {/*                                                setmodelShow(true);*/}
            {/*                                                seteditMode(true);*/}
            {/*                                                setEditId(i);*/}
            {/*                                            }}*/}
            {/*                                        >*/}
            {/*                                            <svg*/}
            {/*                                                xmlns="http://www.w3.org/2000/svg"*/}
            {/*                                                width="18"*/}
            {/*                                                height="18"*/}
            {/*                                                viewBox="0 0 18 18"*/}
            {/*                                                fill="none"*/}
            {/*                                            >*/}
            {/*                                                <path*/}
            {/*                                                    d="M15.3334 5.16644C14.0835 5.58308 12.4169 3.91654 12.8336 2.66663M12.7325 2.76773L10.0396 5.46054C8.86897 6.63122 8.03847 8.09805 7.63693 9.7042L7.50681 10.2247C7.46627 10.3868 7.61316 10.5337 7.77533 10.4932L8.2958 10.3631C9.90195 9.96153 11.3688 9.13103 12.5395 7.96035L15.2323 5.26754C15.5638 4.93604 15.75 4.48644 15.75 4.01763C15.75 3.0414 14.9586 2.25 13.9824 2.25C13.5136 2.25 13.064 2.43623 12.7325 2.76773Z"*/}
            {/*                                                    stroke="#363853"*/}
            {/*                                                />*/}
            {/*                                                <path*/}
            {/*                                                    d="M9 2.25C8.2325 2.25 7.46501 2.33822 6.71282 2.51466C4.62976 3.00328 3.00328 4.62975 2.51466 6.71282C2.16178 8.21719 2.16178 9.78281 2.51466 11.2872C3.00328 13.3702 4.62976 14.9967 6.71283 15.4853C8.21719 15.8382 9.78281 15.8382 11.2872 15.4853C13.3702 14.9967 14.9967 13.3702 15.4853 11.2872C15.6618 10.535 15.75 9.76748 15.75 8.99998"*/}
            {/*                                                    stroke="#363853"*/}
            {/*                                                    stroke-linecap="round"*/}
            {/*                                                />*/}
            {/*                                            </svg>*/}
            {/*                                        </IconButton>*/}
            {/*                                        <IconButton*/}
            {/*                                            sx={{padding: "0"}}*/}
            {/*                                            onClick={() => {*/}
            {/*                                                setDeleteModal((pre) => ({*/}
            {/*                                                    ...pre, service: true,*/}
            {/*                                                }));*/}
            {/*                                                setEditId(i);*/}
            {/*                                            }}*/}
            {/*                                        >*/}
            {/*                                            <svg*/}
            {/*                                                xmlns="http://www.w3.org/2000/svg"*/}
            {/*                                                width="24"*/}
            {/*                                                height="24"*/}
            {/*                                                viewBox="0 0 24 24"*/}
            {/*                                                fill="none"*/}
            {/*                                            >*/}
            {/*                                                <path*/}
            {/*                                                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"*/}
            {/*                                                    fill="#FFD8D8"*/}
            {/*                                                />*/}
            {/*                                                <path*/}
            {/*                                                    d="M9.17 14.8299L14.83 9.16992"*/}
            {/*                                                    stroke="#FF0000"*/}
            {/*                                                    strokeWidth="1.5"*/}
            {/*                                                    stroke-linecap="round"*/}
            {/*                                                    stroke-linejoin="round"*/}
            {/*                                                />*/}
            {/*                                                <path*/}
            {/*                                                    d="M14.83 14.8299L9.17 9.16992"*/}
            {/*                                                    stroke="#FF0000"*/}
            {/*                                                    strokeWidth="1.5"*/}
            {/*                                                    stroke-linecap="round"*/}
            {/*                                                    stroke-linejoin="round"*/}
            {/*                                                />*/}
            {/*                                            </svg>*/}
            {/*                                        </IconButton>*/}
            {/*                                    </Box>*/}
            {/*                                </Box>*/}
            {/*                                <span>{service?.service_type}</span>*/}
            {/*                                <hr/>*/}
            {/*                                <Box>*/}
            {/*                                    {service?.address &&*/}
            {/*                                        <Box className="d-flex gap-2 align-items-center">*/}
            {/*                                            <img src={locationIcon} alt="clock_Icon"/>*/}
            {/*                                            <span style={{fontWeight: "600"}}>*/}
            {/*                                        {service?.address}*/}
            {/*                                    </span>*/}
            {/*                                        </Box>}*/}
            {/*                                    {*/}
            {/*                                        service?.details_link && <>link</>*/}
            {/*                                    }*/}
            {/*                                    {(service?.session_hour || service?.session_minute) &&*/}
            {/*                                        <Box className="d-flex gap-2 align-items-center">*/}
            {/*                                            <img src={clockIcon} alt="clock_Icon"/>*/}
            {/*                                            <span style={{fontWeight: "600"}}>*/}
            {/*                                        {`${service?.session_hour}:${service?.session_minute} Hours`}*/}
            {/*                                    </span>*/}
            {/*                                        </Box>}*/}
            {/*                                </Box>*/}
            {/*                                <Box className="d-flex gap-5 mt-3">*/}
            {/*                                    <Box>*/}
            {/*                                        <Box>*/}
            {/*                                            <span className="grey-text">Session Price</span>*/}
            {/*                                            <p style={{margin: "0"}}>*/}
            {/*                                                ${service?.session_price || 0}*/}
            {/*                                            </p>*/}
            {/*                                        </Box>*/}
            {/*                                        <Box className="mt-2">*/}
            {/*                                               <span className="grey-text">*/}
            {/*                                                   Additional Price*/}
            {/*                                               </span>*/}
            {/*                                            <p style={{margin: "0"}}>*/}
            {/*                                                ${service?.additional_price || 0}*/}
            {/*                                            </p>*/}
            {/*                                        </Box>*/}
            {/*                                    </Box>*/}
            {/*                                    <Box>*/}
            {/*                                        <Box>*/}
            {/*                                            <span className="grey-text">Participants</span>*/}
            {/*                                            <p style={{margin: "0"}}>*/}
            {/*                                                {service?.participants || 0}*/}
            {/*                                            </p>*/}
            {/*                                        </Box>*/}
            {/*                                        <Box className="mt-2">*/}
            {/*                                                <span className="grey-text">*/}
            {/*                                                  Maximum Participants*/}
            {/*                                                </span>*/}
            {/*                                            <p style={{margin: "0"}}>*/}
            {/*                                                {service?.maximum_participants || 0}*/}
            {/*                                            </p>*/}
            {/*                                        </Box>*/}
            {/*                                    </Box>*/}
            {/*                                </Box>*/}
            {/*                                <hr/>*/}
            {/*                                <span className="grey-text"*/}
            {/*                                      style={{wordWrap: "break-word"}}>{service?.description}</span>*/}
            {/*                            </Box>*/}
            {/*                        </Box>*/}
            {/*                    </Grid>))}*/}
            {/*            </Grid>*/}
            {/*        </Box>*/}
            {/*    </Box>}*/}

            {/*<Box*/}
            {/*    className="d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center mt-3">*/}
            {/*    <button className="btn_admina" style={{maxWidth: "400px"}}*/}
            {/*            // onClick={() => setmodelShow(true)}*/}
            {/*    >*/}
            {/*        <img src={plusIcon} alt="" style={{marginRight: "10px"}}/>*/}
            {/*        Add Service*/}
            {/*    </button>*/}
            {/*    <button*/}
            {/*        className="btn_White ms-lg-auto"*/}
            {/*        // onClick={() => {*/}
            {/*        //     setInputData(myDetails);*/}
            {/*        // }}*/}
            {/*    >*/}
            {/*        Cancel*/}
            {/*    </button>*/}
            {/*    <button*/}

            {/*        className="btn_admina"*/}
            {/*        // onClick={() => {*/}
            {/*        //     if (inputData?.provider_introduction.trim()) {*/}
            {/*        //         console.log(inputData, 'inputData')*/}
            {/*        //         dispatch(addMyPageDetails({...inputData, deleteId: deleteId},));*/}
            {/*        //         handleSave()*/}
            {/*        //         setLinkError("")*/}
            {/*        //     } else {*/}
            {/*        //         toast.error("Please Add Provider Introductions.")*/}
            {/*        //     }*/}
            {/*        // }}*/}
            {/*    >*/}
            {/*        Save*/}
            {/*    </button>*/}
            {/*</Box>*/}
            {/*</div>*/}
            {/*</div>*/}
        </>
    )
}

export default IntroNlink;