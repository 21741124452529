// assets
import {
    AntDesignOutlined,
    FontSizeOutlined,
    AppstoreAddOutlined,
    UserOutlined,
    ProfileOutlined,
    ProjectOutlined,
    SwapOutlined,
    DeliveredProcedureOutlined,
    MonitorOutlined,
    PlusOutlined,
    FileImageOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    RadarChartOutlined,
    TeamOutlined,
    FileTextOutlined 

} from '@ant-design/icons';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

// icons
const icons = {
    FontSizeOutlined,
    AntDesignOutlined,
    AppstoreAddOutlined,
    UserOutlined,
    ProfileOutlined,
    ProjectOutlined,
    SwapOutlined,
    DeliveredProcedureOutlined,
    MonitorOutlined,
    PlusOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    FileImageOutlined,
    ConfirmationNumberOutlinedIcon,
    RadarChartOutlined,
    TeamOutlined,
    FileTextOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
    id: 'utilities',
    type: 'group',
    children: [
        // {
        //     id: 'util-category',
        //     title: 'Categories',
        //     type: 'item',
        //     forPagination: 'category',
        //     url: '/categories',
        //     icon: icons.AppstoreAddOutlined
        // },
        {
            id: 'util-user',
            title: 'Users',
            type: 'item',
            forPagination: 'users',
            url: '/users',
            icon: icons.UserOutlined
        },
        {
            id: 'util-providers',
            title: 'Providers',
            type: 'item',
            forPagination: 'providers',
            url: '/provider',
            icon: icons.TeamOutlined
        },
        {
            id: 'util-event',
            title: 'Events',
            type: 'item',
            forPagination: 'events',
            url: '/events',
            icon: icons.ProjectOutlined
        },
        {
            id: 'util-services',
            title: 'Services',
            type: 'item',
            forPagination: 'services',
            url: '/services',
            icon: icons.RadarChartOutlined
        },
        {
            id: 'util-salesTax',
            title: 'Sales Tax',
            type: 'item',
            forPagination: 'salesTax',
            url: '/sales-tax',
            icon: icons.MonitorOutlined
        },
        {
            id: 'util-prePolution',
            title: 'PRE-POPULATION',
            type: 'item',
            forPagination: 'prePolution',
            url: '/pre-polutation',
            icon: icons.PlusOutlined
        },
        {
            id: 'util-bannerImage',
            title: 'Banner Image',
            type: 'item',
            forPagination: 'bannerImage',
            url: '/banner-image',
            icon: icons.AppstoreAddOutlined
        },
        {
            id: 'util-transition',
            title: 'Transactions',
            type: 'item',
            forPagination: 'Transactions',
            url: '/transactions',
            icon: icons.SwapOutlined
        },
        {
            id: 'util-support',
            title: 'Support',
            type: 'item',
            forPagination: 'Support',
            url: '/support',
            icon: icons.SettingOutlined
        },
        {
            id: 'util-refcode',
            title: 'Referral-Codes',
            type: 'item',
            forPagination: 'Referral-Codes',
            url: '/referral-codes',
            icon: icons.UsergroupAddOutlined
        },
        {
            id: 'util-discount',
            title: 'Discount-Codes',
            type: 'item',
            forPagination: 'Discount-Codes',
            url: '/discount-codes',
            icon: icons.ConfirmationNumberOutlinedIcon
        },
        {
            id: 'util-prePopEvent',
            title: 'PRE-POPULATION EVENTS',
            type: 'item',
            forPagination: 'PRE-POLUTATION EVENTS',
            url: '/pre-pop-events',
            icon: icons.PlusOutlined
        },
        {
            id: 'util-reports',
            title: 'REPORTS',
            type: 'item',
            forPagination: 'REPORTS',
            url: '/reports',
            icon: icons.FileTextOutlined
        },
       
        // {
        //     id: 'util-transaction',
        //     title: 'Transaction',
        //     type: 'item',
        //     forPagination: 'transactions',
        //     url: '/transaction',
        //     icon: icons.SwapOutlined
        // }
        // {
        //     id: 'util-shippingproduct',
        //     title: 'Shipping Products',
        //     type: 'item',
        //     forPagination: 'shippingProduct',
        //     url: '/shippingproduct',
        //     icon: icons.DeliveredProcedureOutlined
        // }
    ]
};

export default utilities;
